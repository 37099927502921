import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import HeaderSection from "../components/Company/HeaderSection"
import AboutSection from "../components/Company/AboutSection"
import ValuesSection from "../components/Company/ValuesSection"
import PeopleSection from "../components/Company/PeopleSection"
import { mainUrl } from "../js/config"

export default function Company() {
  return (
    <section>
      <SEO
        title="Eastern European IT Services Provider — OS-System"
        description="OS-System is an Eastern European IT services provider. We successfully deploy modern web and software development solutions since 2007."
        canonical={`${mainUrl}/company/`}
      />
      <Layout showFormPoint={100}>
        <HeaderSection />
        <AboutSection />
        <ValuesSection />
        <PeopleSection />
      </Layout>
    </section>
  )
}
