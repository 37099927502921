import React from "react"

import "./AboutHeader.scss"

export default function HeaderSection() {
  return (
    <section className="company-about-header">
      <div className="company-about-container">
        <div className="header">
          <h1 className="header-title">
            About us
          </h1>
          <iframe  src="https://www.youtube.com/embed/FzQ-CxP740k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </section>
  )
}
