import React from "react"

import "./AboutSection.scss"

export default class AboutSection extends React.Component {
  render() {
    return (
      <section className="company-about-section">
        <div className="container">
          <div className="row">
            <div className="company-about_item  col-md-6 col-sm-6 col-xs-12">
              <h4>Mission</h4>
              <p>
                Our Mission is to be always open and cooperative. We strive to
                create the most transparent environment to help our customers
                solve business tasks with both quality and delivery dates
                guaranteed.
                <br />
                <br />
                For us, transparency means permanent and direct communication
                between a customer and a team of developers leading to a clear
                distribution of responsibilities and a better understanding of
                the work progress. Our customers always know what the status of
                the project is and what the money is paid for.
              </p>
            </div>
            <div className="company-about_item col-md-6 col-sm-6 col-xs-12">
              <h4>History</h4>
              <p>
                Established in 2007, we evolved from a 3-guys start-up to a 40+
                mobile/web app development agency with a tailored tech stack. We
                elaborately narrowed our services to be based on JavaScript and
                PHP (Node, React, React Native, Angular, Symfony, Laravel; and
                WebRTC as standalone expertise).
                <br />
                <br />
                This allowed us to avoid scatteration and focus on quality. We
                work with both private and public sectors meeting all intricate
                requirements that the latter often lays down.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
