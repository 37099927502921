import React from "react"
import Slider from "react-slick"
import {
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
  Slide9,
} from "../../../images/Company/sliders"

import "./PeopleSection.scss"

const settings = {
  dots: true,
  speed: 400,
  infinite: true,
  draggable: true,
  arrows: true,
  slidesToShow: 3,
  centerMode: true,
  slidesToScroll: 1,
  variableWidth: true,
  cssEase: "ease-in-out",
  useTransform: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        arrows: false,
        centerMode: true,
        speed: 450,
        cssEase: "ease-out",
      },
    },
    {
      breakpoint: 1019,
      settings: {
        speed: 300,
        cssEase: "ease-in",
        useTransform: false,
        adaptiveHeight: true,
        arrows: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        speed: 300,
        // lazyLoad: true,
        cssEase: "ease-out",
        useTransform: true,
      },
    },
    
  ],
}


export default function PeopleSection() {
  const sliderImg = [
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5,
    Slide6,
    Slide7,
    Slide8,
    Slide9,
  ]
  return (
    <section className="company-about-team">
      <div className="container">
        <div className="about-team">
          <h4 className="about-team-title">Our people</h4>
          <p className="about-team-description">
            We are driven by a passion to create and build disruptive and
            innovative applications that help people solve real problems{" "}
          </p>
        </div>
        <div className="company-carousel-wrapper">
          <Slider {...settings}>
            {sliderImg.map((item, i) => (
                <img className="review-item" key={i} src={item} alt="Our team photo" />
              ))}
          </Slider>
        </div>
               
      </div>
    </section>
  )
}