import React from "react"

import "./ValuesSection.scss"

export default class ValuesSection extends React.Component {
  render() {
    return (
      <section className="values">
        <div className="values_title">Values</div>
        <div className="values_wrapper">
          <div className="values_block-wrapper">
            <div className="values_item-block">
              <div className="item_header">
                <div className="item_title">Commitment to Clients</div>
                <div className="item_number">01.</div>
              </div>
              <p>
                We are committed to providing you with the highest levels of
                service that we can deliver. We continually assess our
                performance, the evolving needs of IT-business in general and
                our client base in particular, and adapt and improve our
                processes/performance accordingly to ensure we continue to
                deliver a service that meets or exceeds the needs and
                expectations of our clients and partners.
              </p>
            </div>
            <div className="values_item-block">
              <div className="item_header">
                <div className="item_title">Personal Development</div>
                <div className="item_number">02.</div>
              </div>
              <p>
                When employees know you care about their growth, they respond.
                It builds trust, commitment, and a host of other benefits that
                impact engagement, productivity, and the bottom line. Our most
                valuable resource is our staff. We perfectly understand that
                ongoing professional development directly enhances the value
                of this asset hence we tend to create a corporate culture
                in which ongoing professional development is not only welcome
                but encouraged and fostered.
              </p>
            </div>
          </div>
          <div className="values_block-wrapper">
            <div className="values_item-block">
              <div className="item_header">
                <div className="item_title">Integrity and Honesty</div>
                <div className="item_number">03.</div>
              </div>
              <p>
                Our Data Protection Policy guarantees a high standard of
                compliance culture followed by each and every employee of
                OS-system. We ensure the highest degree of confidentiality and
                protection of our clients’ intellectual properties, contracts,
                strategies, trade secrets, and personal information.
              </p>
            </div>
            <div className="values_item-block">
              <div className="item_header">
                <div className="item_title">Think Ahead of Time</div>
                <div className="item_number">04.</div>
              </div>
              <p>
                Technology is evolving rapidly. This very pace of technology and
                the rate at which it is getting updated can cause both
                developers and users to be left out and outdated if they do not
                keep up. Over the years we have accumulated enough understanding
                of the industry to recognize the strengths and weaknesses
                of a particular language or a technology and analyze their
                relevance. To do so, we constantly update your knowledge, test,
                read and post our views on trending technologies.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
